<template>
  <div class="preview-box mt-2 mb-2">
    <div class="image-box">
      <b-img class="img-preview-img h-100" :src="image.preview" fluid rounded="0" />
    </div>
    <div class="controls-box">
      <span>{{ image.fileName }}</span>
      <span><b-icon-x @click="handleDelete" /></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImagePreview',
  props: {
    image: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleDelete() {
      this.$emit('deleteImage');
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-box {
  width: 100%;
  height: 100px;
  display: flex;
  gap: 15px;

  & > .image-box {
    width: 150px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($color: #ababab, $alpha: 0.5);

    & > img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  & > .controls-box {
    flex: 0.5;
    gap: 15px;
    display: flex;

    & > span > svg {
      font-size: 24px;
      cursor: pointer;
    }
  }
}
</style>
