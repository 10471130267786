<script>
import Vue from 'vue';

import { convertFromURLToFile } from '@/constants/utils/file';

export default {
  name: 'FileUploadMixin',

  data() {
    return {
      files: {},
      showImagesModal: {},
    };
  },

  computed: {
    filesArray() {
      return Object.values(this.files);
    },
  },

  methods: {
    setFileUploadData(fileFields, modalFields) {
      fileFields.forEach((field) => {
        Vue.set(this.files, field, null);
      });

      modalFields.forEach((field) => {
        Vue.set(this.showImagesModal, field, false);
      });
    },

    toggleShowModal(modalName) {
      this.showImagesModal[modalName] = !this.showImagesModal[modalName];
    },
    handleFile({ file, preview, fileName }, field) {
      this.files[field] = {
        file,
        preview,
        fileName,
      };
    },
    handleFiles(filesArray, field) {
      filesArray.forEach(({ file, preview, fileName }, index) => {
        this.files[field].push({
          file,
          preview,
          fileName,
        });
      });
    },
    async setImage(url, field) {
      if (!url) return;

      const fileName = url.split('/').pop();
      const file = await convertFromURLToFile(url, fileName);

      this.handleFile({ file, preview: url, fileName }, field);
    },
    setImages(files, field) {
      if (!files) return;

      files.forEach(async (record, index) => {
        const fileName = record.url.split('/').pop();
        const file = await convertFromURLToFile(record.url, fileName);

        this.files[field].push({
          file,
          preview: record.url,
          fileName: fileName,
        });
      });
    },
    handleDelete(field) {
      Vue.set(this.files, field, null);
    },
    handleDeleteFromMany(field, index) {
      this.files[field].splice(index, 1);
    },
  },
};
</script>
